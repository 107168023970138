import styled from "styled-components"
import { Container, Section } from "../../Global"

export const H2Info = styled.h2`
  font-size: ${props => props.theme.fontSize.giant};
  color: ${props => props.theme.fontSize.black};
  margin: 0;
  text-align: center;
  line-height: 100px;
  font-weight: ${props => props.theme.fontWeight.normal};
  @media (max-width: 690px) {
    font-size: 40px;
    line-height: 50px;
  }
`

export const InfoContainer = styled(Container)`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  @media (max-width: 690px) {
    height: 50px;
  }
`
export const InfoSection = styled(Section)`
  flex-direction: column;
`
