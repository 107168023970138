import React, { CSSProperties } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel as Slider } from "react-responsive-carousel"
import {
  CarouselContainer,
  ItemWrapper,
  AdvertiseSection,
  ImageWrapper,
  AdvertiseBackground,
  AdvertiseText,
  AdvertiseTitle,
} from "./styles"
import Button from "../../UI/Button"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const indicatorStyles: CSSProperties = {
  background: "gray",
  width: 16,
  height: 16,
  display: "inline-block",
  margin: "0 8px",
}

const AdvertiseCarousel = () => {
  const advertiseQuery = useStaticQuery(graphql`
    query advertiseQuery {
      file(name: { eq: "click4fitadvertise" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <AdvertiseSection>
      <AdvertiseBackground />
      <CarouselContainer>
        <Slider
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          renderArrowPrev={(_onClickHandler, hasPrev, _label) =>
            hasPrev && <></>
          }
          renderArrowNext={(_onClickHandler, hasNext, _label) =>
            hasNext && <></>
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ ...indicatorStyles, background: "#000" }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              )
            }
            return (
              <li
                style={indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            )
          }}
        >
          <ItemWrapper>
            <ImageWrapper>
              <GatsbyImage
                image={advertiseQuery.file.childImageSharp.gatsbyImageData}
                alt="advertise"
              />
            </ImageWrapper>
            <AdvertiseTitle>TRENING CARDIO</AdvertiseTitle>
            <AdvertiseText>30% ZNIŻKI NA PAKIETY CARDIO</AdvertiseText>
            <Button type="advertise" slug={"/"}>
              WYPRÓBUJ
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <ImageWrapper>
              <GatsbyImage
                image={advertiseQuery.file.childImageSharp.gatsbyImageData}
                alt="advertise"
              />
            </ImageWrapper>
            <AdvertiseTitle>TRENING CARDIO</AdvertiseTitle>
            <AdvertiseText>30% ZNIŻKI NA PAKIETY CARDIO</AdvertiseText>
            <Button type="advertise" slug={"/"}>
              WYPRÓBUJ
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <ImageWrapper>
              <GatsbyImage
                image={advertiseQuery.file.childImageSharp.gatsbyImageData}
                alt="advertise"
              />
            </ImageWrapper>
            <AdvertiseTitle>TRENING CARDIO</AdvertiseTitle>
            <AdvertiseText>30% ZNIŻKI NA PAKIETY CARDIO</AdvertiseText>
            <Button type="advertise" slug={"/"}>
              WYPRÓBUJ
            </Button>
          </ItemWrapper>
        </Slider>
      </CarouselContainer>
    </AdvertiseSection>
  )
}

export default AdvertiseCarousel
