import React from "react"
import ITrainingSlide from "./types"
import { TrainingSlide as Wrapper } from "./styles"
import TrainingCard from "../TrainingCard"

const TrainingSlide = ({ trainingCards, isInChallenge }: ITrainingSlide) => {
  return (
    <Wrapper>
      {trainingCards.map((c, idx) => (
        <TrainingCard
          key={idx}
          authorName={c.author.name}
          trainingTitle={c.video.name}
          authorPicture={c.author.picture_icon}
          trainingVideo={c.video}
          videoSlug={c.video.slug}
          iFrame={c.video.iFrame}
          isInChallenge={isInChallenge}
          price={c.video.price}
          intensity={c.video.intensity}
          rating={c.video.rating}
          lenght={c.video.lenght}
          videoId={c.video.id}
          packageSlug={c.packagee.slug}
        />
      ))}
    </Wrapper>
  )
}

export default TrainingSlide
