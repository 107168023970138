import styled from "styled-components"
import { Container } from "../../Global"
import { FlexCenterCol } from "../../Global"
import { Section } from "../../Global"

export const CarouselContainer = styled(Container)`
  margin: 0px 15px 0px 15px;
  height: 700px;
  width: 1500px;
  @media (max-width: 1530px) {
    width: 100%;
  }
  @media (max-width: 1300px) {
    height: 500px;
  }
  @media (max-width: 530px) {
    height: 400px;
  }
`
export const AdvertiseSection = styled(Section)`
  margin-bottom: 250px;
`
export const AdvertiseBackground = styled.div`
  margin-top: 200px;
  height: 700px;
  width: 100%;
  position: absolute;
  background-color: ${props => props.theme.color.lightGreen};
  @media (max-width: 1530px) {
    height: 600px;
  }
  @media (max-width: 1300px) {
    height: 450px;
  }
  @media (max-width: 530px) {
    height: 400px;
  }
`

export const AdvertiseTitle = styled.h2`
  font-size: 288px;
  font-style: italic;
  color: ${props => props.theme.color.white};
  line-height: 220px;
  font-weight: ${props => props.theme.fontWeight.normal};
  margin: 0 300px 0 300px;
  @media (max-width: 1530px) {
    font-size: 200px;
    line-height: 170px;
  }
  @media (max-width: 1300px) {
    font-size: 150px;
    line-height: 120px;
  }
  @media (max-width: 530px) {
    font-size: 90px;
    line-height: 90px;
  }
`
export const AdvertiseText = styled.span`
  font-size: 64px;
  padding-left: 200px;
  @media (max-width: 1530px) {
    padding-left: 0px;
    font-size: 40px;
  }
  @media (max-width: 1300px) {
    font-size: 30px;
    padding-bottom: 15px;
  }
  @media (max-width: 530px) {
    font-size: 20px;
    padding-bottom: 15px;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  width: 600px;
  left: 0;
  top: 0;
  & > div {
    left: 0;
    width: 100%;
    height: 800px;
    top: -50px;
    position: absolute;
  }
  @media (max-width: 1530px) {
    & > div {
      height: 500px;
      width: 80%;
      top: 200px;
    }
  }
  @media (max-width: 1300px) {
    & > div {
      height: 400px;
      width: 60%;
      top: 125px;
    }
  }
  @media (max-width: 900px) {
    display: none;
  }
`

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 850px;
  overflow: visible;
  @media (max-width: 1300px) {
    height: 650px;
  }
  @media (max-width: 530px) {
    height: 550px;
  }
`
