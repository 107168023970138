import React, { useLayoutEffect, useState, useEffect } from "react"
import Layout from "../components/UI/Layout"
import LandingInfo from "../components/Sections/LandingInfo"
import AdvertiseCarousel from "../components/Sections/AdvertiseCarousel"
import Head from "../components/Global/Head"
import ChallengeCarousel from "../components/Sections/ChallengeCarousel"
import Trainings from "../components/Sections/Trainings"
import { _videos } from "../data/mockData/mockData"

import useIndexQuery from "../data/query"

const Index = ({ pageContext }) => {
  const [screenWidth, setScreenWidth] = useState(0)
  const [sortedData, setSortedData] = useState([])
  const [challengeData, setChallengeData] = useState([])
  function useScreenWidth() {
    useLayoutEffect(() => {
      function updateSize() {
        setScreenWidth(window.innerWidth)
      }
      window.addEventListener("resize", updateSize)
      updateSize()
      return () => window.removeEventListener("resize", updateSize)
    }, [])
    return screenWidth
  }

  let splitTrainingValue = 3
  let splitChallengeValue = 3
  if (useScreenWidth() < 1100) {
    splitTrainingValue = 2
    splitChallengeValue = 2
  }
  if (useScreenWidth() < 650) {
    splitTrainingValue = 1
    splitChallengeValue = 1
  }

  const indexDataCopy = [...pageContext.indexData]
  const categories: any = []
  const challengesCopy = [..._videos]
  for (let i = 0; i < indexDataCopy.length; i++) {
    categories[i] = [...pageContext.indexData[i].categoriesVideos]
  }

  const splitArray = (array: any, chunk: number) => {
    const splitedArray = []
    for (let i = 0; array.length >= chunk; i++) {
      splitedArray[i] = array.splice(0, chunk)
    }
    if (array.length > 0) {
      splitedArray[splitedArray.length] = array.splice(0, array.length)
    }
    return splitedArray
  }

  let splitedTrainingData: any = []
  let splitedChallengeData: any = []

  useEffect(() => {
    for (let i = 0; i < categories.length; i++) {
      splitedTrainingData[i] = splitArray(categories[i], splitTrainingValue)
    }
    splitedChallengeData = splitArray(challengesCopy, splitChallengeValue)
    setSortedData(splitedTrainingData)
    setChallengeData(splitedChallengeData)
  }, [screenWidth])

  const queryData = useIndexQuery()
  return (
    <Layout
      backgroundImage={
        queryData.allFile.edges[0].node.childImageSharp.gatsbyImageData
      }
    >
      <Head
        pageTitle="Platforma Treningowa Online"
        description="" //todo
        keywords="trening online, platforma treningowa online, video trening"
      />
      <LandingInfo />
      <AdvertiseCarousel />
      {sortedData.map(x => (
        <ChallengeCarousel isInChallenge={false} challengeData={x} />
      ))}
      {sortedData.map(x => (
        <Trainings
          isInChallenge={false}
          trainingsData={x}
          title={"TRENING UDERZEŃ"}
        />
      ))}
    </Layout>
  )
}

export default Index
