const _authors = [
  {
    id: "1",
    name: "AUTHOR_NAME",
    picture_icon: "author1_icon.png",
    pictures: ["picture_1.png", "picture_2.png"],
    slug: "author-name",
    about: "lorem ipsum description",
    videosId: ["577474088", "577474036", "577474056", "577474071"],
    packagesId: ["1"],
  },
]

const _videos = [
  {
    id: "577474088",
    packageId: "1",
    name: "Technika uderzenia 1",
    slug: "technika-uderzenia-1",
    authorId: "1",
    iFrame: "",
    price: "15",
    rating: "5",
    intensity: 6,
    lenght: "15:00",
  },
  {
    id: "577474036",
    packageId: "1",
    name: "Technika uderzenia 2",
    slug: "technika-uderzenia-2",
    authorId: "1",
    iFrame: "",
    price: "12",
    rating: "5",
    intensity: 7,
    lenght: "13:00",
  },
  {
    id: "577474056",
    packageId: "1",
    name: "Technika uderzenia 3",
    slug: "technika-uderzenia-3",
    authorId: "1",
    iFrame: "",
    price: "20",
    rating: "5",
    intensity: 7,
    lenght: "16:00",
  },
  {
    id: "577474071",
    packageId: "1",
    name: "Technika uderzenia 4",
    slug: "technika-uderzenia-4",
    authorId: "1",
    iFrame: "",
    price: "27",
    rating: "5",
    intensity: 7,
    lenght: "9:00",
  },
]

const _packages = [
  {
    id: "1",
    authorId: "1",
    name: "TECHNIKI UDERZEŃ",
    slug: "techniki-uderzen-1",
    videosId: ["577474088", "577474036", "577474056", "577474071"],
    price: "100",
    rating: "5",
    intensity: 7,
    lenght: "53",
  },
]

const _categories = [
  {
    id: "1",
    name: "Fitness",
    videosId: ["577474088", "577474036", "577474056", "577474071"],
  },
  {
    id: "2",
    name: "MMA",
    videosId: ["577474088", "577474036", "577474056", "577474071"],
  },
  {
    id: "3",
    name: "Ćwiczenia siłowe",
    videosId: ["577474088", "577474036", "577474056", "577474071"],
  },
]

const _categoriesList = [
  {
    categoryId: 1,
    categoryName: "Fitness",
    categoryTags: ["Tag1", "Tag2", "Tag3"],
    categoryDescription: "Początki fitnessu łączyć należy z wynalezieniem i rozpowszechnieniem podstawowej formy " +
        "gimnastyki rekreacyjnej jaką był aerobik. Kolebką aerobiku są Stany Zjednoczone. Za wynalazcę tej formy " +
        "ćwiczeń uznawany jest dr Kenneth Cooper, który w latach 70. był lekarzem w Narodowej Agencji Aeronautyki USA " +
        "(NASA). Opracowywał on programy kondycyjne dla amerykańskich kosmonautów. Przeprowadził liczne badania " +
        "na wielu tysiącach osób, które pozwoliły mu na stworzenie założeń aerobiku. Cechą charakterystyczną ćwiczeń " +
        "\"aerobowych\" (czyli tlenowych) było to, że układ krążenia i oddychania ćwiczącego pracował w ten sposób, " +
        "aby organizm mógł w trakcie ćwiczeń pobierać dostatecznie dużo tlenu i unikać okresów niedotlenienia.",
    categorySlug: "Fitness",
  },
  {
    categoryId: 2,
    categoryName: "MMA",
    categoryTags: ["Tag4", "Tag5", "Tag6"],
    categoryDescription: "Sztuki walki – są sposobami walki wręcz, czasami z użyciem broni białej. Podstawą nauki " +
        "wszelkich sztuk walki jest trening, który zazwyczaj uczy umiejętności samokontroli wybuchów agresji, " +
        "samoobrony, poprawia wydolność organizmu oraz redukuje poziom strachu.",
    categorySlug: "MMA",
  },
  {
    categoryId: 3,
    categoryName: "Ćwiczenia siłowe",
    categoryTags: ["Tag7", "Tag8", "Tag9"],
    categoryDescription: "Trening siłowy kojarzony jest przez większość społeczeństwa wyłącznie z treningiem, " +
        "który można wykonywać na siłowni. Duże obciążenie, maszyny, sztangi, hantle, kettle… Czy wszystkie te sprzęty " +
        "są niezbędne do wykonania dobrego i efektywnego treningu siłowego?",
    categorySlug: "Ćwiczenia siłowe",
  },
]

export { _packages, _videos, _authors, _categories, _categoriesList }
