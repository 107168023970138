import React from "react"
import { Container } from "../../Global"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel as Slider } from "react-responsive-carousel"
import { CarouselContainer, ColoredSection } from "./styles"
import { H2WhiteTitle, M } from "../../Global"
import { SliderArrow } from "../../Elements/SliderArrow"
import TrainingSlide from "../../Elements/TrainingSlide"
import IChallengeCarousel from "./types"

const ChallengeCarousel = ({
  challengeData,
  isInChallenge,
}: IChallengeCarousel) => {
  return (
    <ColoredSection>
      <Container>
        <M>
          <H2WhiteTitle>WYZWANIA 30 DNIOWE</H2WhiteTitle>
        </M>
      </Container>
      <CarouselContainer>
        <M>
          <Slider
            showThumbs={false}
            autoPlay={false}
            interval={15000}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <SliderArrow
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    left: 15,
                    borderTop: "3px solid ",
                    borderLeft: "3px solid ",
                  }}
                />
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <SliderArrow
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    right: 15,
                    borderBottom: "3px solid",
                    borderRight: "3px solid ",
                  }}
                />
              )
            }
            renderIndicator={(_onClickHandler, _isSelected, _index, _label) => (
              <></>
            )}
          >
            {challengeData.map((t: any, idx: number) => (
              <TrainingSlide
                key={idx}
                trainingCards={t}
                isInChallenge={isInChallenge}
              />
            ))}
          </Slider>
        </M>
      </CarouselContainer>
    </ColoredSection>
  )
}

export default ChallengeCarousel
