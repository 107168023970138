import styled from "styled-components"
import { Container, Section } from "../../Global"
import { TripleGrid } from "../../Global"

export const TrainingSlide = styled(TripleGrid)`
  position: relative;
  width: 100%;
  gap: 20px;
`

export const ColoredSection = styled(Section)`
  background-color: ${props => props.theme.color.darkGreen};
  height: 590px;
  flex-direction: column;
  margin: 40px 0 40px 0;
  padding-top: 30px;
`

export const CarouselContainer = styled(Container)`
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
`
