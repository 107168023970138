import * as React from "react"
import { M } from "../../Global"
import { H2Info, InfoContainer, InfoSection } from "./styles"

const LandingInfo = () => {
  return (
    <InfoSection>
      <InfoContainer>
        <M>
          <H2Info>ZNAJDŹ NAJLEPSZE TRENINGI DLA SIEBIE</H2Info>
        </M>
      </InfoContainer>
    </InfoSection>
  )
}

export default LandingInfo
