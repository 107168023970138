import React from "react"
import { Container } from "../../Global"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel as Slider } from "react-responsive-carousel"
import { CarouselContainer, TrainingsSection } from "./styles"
import { H2BlackTitle, M, GrayBorder } from "../../Global"
import { SliderArrow } from "../../Elements/SliderArrow"
import TrainingSlide from "../../Elements/TrainingSlide"
import ITrainingsData from "./types"

const Trainings = ({ trainingsData, title, isInChallenge }: ITrainingsData) => {
  return (
    <TrainingsSection>
      <Container>
        <M>
          <H2BlackTitle>{title}</H2BlackTitle>
        </M>
      </Container>
      <CarouselContainer>
        <M>
          <Slider
            showThumbs={false}
            autoPlay={false}
            interval={15000}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <SliderArrow
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    left: 15,
                    borderTop: "4px solid ",
                    borderLeft: "4px solid ",
                  }}
                />
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <SliderArrow
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    right: 15,
                    borderBottom: "4px solid",
                    borderRight: "4px solid ",
                  }}
                />
              )
            }
            renderIndicator={(_onClickHandler, _isSelected, _index, _label) => (
              <></>
            )}
          >
            {trainingsData.map((t, idx) => (
              <TrainingSlide
                key={idx}
                trainingCards={t}
                isInChallenge={isInChallenge}
              />
            ))}
          </Slider>
        </M>
      </CarouselContainer>
      <GrayBorder />
    </TrainingsSection>
  )
}

export default Trainings
